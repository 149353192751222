<div *ngIf="titleImageClass$ | async as imgcls" class="super-image" [ngClass]="imgcls"></div>

<div class="page-inner main">
  <div class="welcome-grid" *ngIf="appFeatures$ | async as features">
    <div *ngIf="features.loggedIn && features.betaTester">
      <h2 translate="WELCOME.REPORT_HEADER" routerLink="/plants/report"></h2>
      <app-report-now></app-report-now>
    </div>
    <div class="betterb-welcome" *ngIf="features.betterBIntro">
      <h2 translate="BETTERB.WELCOME.HEADER"></h2>
      <div>
        <p translate="BETTERB.WELCOME.INTRO"></p>
        <p class="button-block">
          <a mat-raised-button color="accent" routerLink="/user/signup"  translate="BETTERB.WELCOME.SIGNUP_BUTTON"></a>
          <a mat-raised-button class="welcome-navlink" routerLink="/user/login" translate="USER.PAGES.LOGIN"></a>
        </p>

      </div>
      <mat-divider></mat-divider>
      <h3 translate="PAGES.TITLES.HELP"></h3>
      <div>
        <p translate="BETTERB.WELCOME.HELP_INTRO"></p>
        <a mat-raised-button class="welcome-navlink" routerLink="/doc" translate="PAGES.TITLES.HELP"></a>
      </div>
      <!-- Report Download, 2nd place (for not logged in)-->
      <mat-divider></mat-divider>
      <div class="download">
        <mat-icon inline>download</mat-icon>
        <a href="/static/reports/beeplants-eu-report-2024-1.pdf" target="_blank">2024 Beeplants.eu Report</a>
      </div>
    </div>
    <div>
      <h2 translate="WELCOME.REPORT_HEADER" routerLink="/plants/report"></h2>
      <p>
        {{ 'WELCOME.REPORT_TEXT' | translate }}
      </p>
      <p  class="button-block">
        <a class="welcome-navlink" routerLink="/plants/report" translate="WELCOME.REPORT_BUTTON" mat-raised-button></a>
        <a *ngIf="features.loggedIn" class="welcome-navlink" routerLink="/plants/report/list" translate="REPORT.TITLE_REPORTLIST" mat-raised-button></a>
      </p>
      <app-greeter></app-greeter>
    </div>

    <div>
      <div class="catalogue-block">
        <h2 translate="WELCOME.CATALOGUE_HEADER" routerLink="/plants"></h2>
        <div routerLink="/plants" class="panel-image"
          style="background-image: url('/assets/images/blue-flower-lo.jpg')"></div>
        <p translate="WELCOME.CATALOGUE_TEXT"></p>
        <p class="button-block">
          <a mat-raised-button class="welcome-navlink" routerLink="/plants/list/bloom"
            translate="PAGES.TITLES.CATALOGUE_BLOOMING"></a>
          <a mat-raised-button class="welcome-navlink" routerLink="/plants/list/title"
            translate="PAGES.TITLES.CATALOGUE_ALPHA"></a>
        </p>
      </div>
    </div>

    <div *ngIf="features.modes.pollen" class="seamless-image white">
      <h2 translate="WELCOME.POLLENID_HEADER" routerLink="/plants/find"></h2>
      <div routerLink="/plants/find" class="panel-image"
        style="background-image: url('assets/images/pollen-title.jpg')"></div>
      <p translate="WELCOME.POLLENID_TEXT"></p>
      <p class="button-block">
        <a mat-raised-button class="welcome-navlink" routerLink="/plants/find">
          {{ 'WELCOME.POLLENID_BUTTON' | translate }}
        </a>
      </p>
    </div>

    <!-- Help / About-->
    <div *ngIf="!features.betterBIntro" [ngClass]="features.betterBActive ? 'betterb-welcome' : ''">
      <h2 translate="WELCOME.ABOUT_HEADER" routerLink="/misc/about"></h2>
      <p translate="WELCOME.ABOUT_TEXT"></p>
      <p class="button-block">
        <a mat-raised-button class="welcome-navlink" routerLink="/misc/about" translate="WELCOME.ABOUT_BUTTON"></a>
        <ng-container *ngIf="features.betaTester || features.betterBActive">
          <a mat-raised-button class="welcome-navlink" routerLink="/doc" translate="PAGES.TITLES.HELP"></a>
        </ng-container>
      </p>
      <!-- Report Download, 2nd place (for not logged in)-->
      <mat-divider></mat-divider>
      <div class="download">
        <mat-icon inline>download</mat-icon>
        <a href="/static/reports/beeplants-eu-report-2024-1.pdf" target="_blank">2024 Beeplants.eu Report</a>
      </div>
    </div>

    <div *ngIf="!features.betterBIntro" class="white">
      <h2 translate="WELCOME.USER_HEADER" routerLink="/user/user"></h2>
      <p translate="WELCOME.USER_TEXT"></p>
      <ng-container *ngIf="authStatus$ | async as authStatus">
        <ng-container *ngIf="authStatus.status; else notloggedin">
          <p>
            <a class="welcome-navlink" routerLink="/user/user">
              {{ 'USER.TEXTS.LOGGED_IN_AS' | translate }}
              <b>{{ authStatus.user.username }}</b></a>
          </p>
          <a mat-raised-button class="welcome-navlink" routerLink="/user/user" translate="USER.PAGES.USER"></a>
        </ng-container>
        <ng-template #notloggedin>
          <p class="button-block">
            <a mat-raised-button class="welcome-navlink" routerLink="/user/login" translate="USER.PAGES.LOGIN"></a>
            <a mat-raised-button class="welcome-navlink" routerLink="/user/signup" translate="USER.PAGES.SIGNUP"></a>
          </p>
        </ng-template>
      </ng-container>
    </div>

    <!--
    <div>
      <pre>{{features|json}}</pre>
    </div>
    -->

    <!-- Suggestions for beeplants.eu (and authenticated) only -->
    <div *ngIf="features.features.suggestions && features.betterBActive">
      <h2 translate="SUGGESTIONS.WELCOME_TITLE"></h2>
      <p translate="SUGGESTIONS.INFO_PARAGRAPH"></p>
      <p class="button-block">
        <a mat-raised-button class="welcome-navlink" routerLink="/suggestions">{{
          'SUGGESTIONS.TITLE' | translate
          }}</a>
      </p>
    </div>
  </div>
</div>