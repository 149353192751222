import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, ScatterChart, PieChart, CandlestickChart } from 'echarts/charts';

import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components';

import { CanvasRenderer } from 'echarts/renderers';
import { EchartComponent } from './echart.component';

echarts.use([
  BarChart,
  LineChart,
  ScatterChart,
  PieChart,
  CandlestickChart,
  LegendComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  CanvasRenderer,
]);


@NgModule({
  declarations: [EchartComponent],
  imports: [CommonModule],
  exports: [EchartComponent],
})
export class EchartsModule { }
