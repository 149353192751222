import { createSelector } from '@ngrx/store';
import { combineNetworkState } from 'src/app/modules/utils/helpers/network-entities';
import { AppSelectors } from 'src/app/state/app/selectors';
import { PlantsSelectors } from '../plants/selectors';
import { State } from '../reducers';
import { groupBy, orderBy } from 'lodash-es';
import { reportCountData } from 'src/app/modules/report/util/reporthelper';
import { sub, isAfter } from 'date-fns';
import { filterReportsByCloseness } from 'src/app/helpers/location';
import { PlantReport } from 'src/app/modules/report/types/plant-report';
import { PlantSchemaLocalized } from 'src/app/modules/plants/types';

type SimplifiedLocalizedPlant  = Pick<PlantSchemaLocalized, 'id' | 'name' | 'thumbnail' | 'colorsPollen' | 'species'>;
function mapPlantForReport(plant: PlantSchemaLocalized): SimplifiedLocalizedPlant {
  return {
    id: plant.id,
    name: plant.name,
    thumbnail: plant.thumbnail,
    colorsPollen: plant.colorsPollen,
    species: plant.species
  }
}

export const reportStateSelector = (state: State) => state.report;

const TOPPLANTS_COUNT = 4;

export class ReportSelectors {
  /**
   * Plants that can be reported for blooming, sorted.
   * Filter by starting to bloom soon or blooming
   */
  static reportablePlants = createSelector(
    PlantsSelectors.extendedPlantsLimitedByRegion,
    (plants) => {
      const reportable = plants
        .filter((plant) => !!plant.reporting)
        .map((plant) => ({
          ...plant,
          blmStartMthRelative: bloomingRelative(plant),
        }));
      reportable.sort(reportPlantsSorter);
      return reportable;
    }
  );

  // User's own reports
  // NOTE: Augmented with localized plant data (such as translated name)
  // NOTE: sorted by date
  static getReports = createSelector(
    reportStateSelector,
    PlantsSelectors.extendedPlants,
    (state, plants) =>
      orderBy(
        (state.reports || []).map((report) => ({
          ...report,
          reportedPlant: mapPlantForReport(plants.find((plant) => plant.id === report.reportedPlant.id)),
        })),
        'reportDate',
        'desc'
      )
  );

  static getSpots = createSelector(reportStateSelector, (state) =>
    orderBy(state.spots || [], 'maxDate', 'desc')
  );
  static getSpotsWithPlants = createSelector(
    ReportSelectors.getSpots,
    PlantsSelectors.extendedPlantsLimitedByRegion,
    (spots, plants) =>
      spots.map((spot) => ({
        ...spot,
        reportedPlant: plants.find((plant) => plant.id === spot.plantId),
      }))
  );

  /**
   * Get all reports within last 6 months, that are close to this location.
   * Group by plant.
   * Return reports and the geoposition.
   */
  static getCloseReports = createSelector(
    ReportSelectors.getReports,
    AppSelectors.improvedGeopos,
    (reports: PlantReport[], geopos) => {
      if (!geopos) {
        return {
          plants: [],
          geopos: null,
        };
      }
      const limitDate = sub(new Date(), { months: 6 });

      let closeReports = filterReportsByCloseness<PlantReport>(
        reports.filter((report) => isAfter(new Date(report.reportDate), limitDate)),
        geopos
      );

      // Augment with count data
      closeReports = closeReports.map((report) => ({
        ...report,
        countData: reportCountData(report),
      }));

      closeReports = orderBy(closeReports, 'reportDate', 'desc');
      closeReports = groupBy(closeReports, 'reportedPlant.id');

      const plants = Object.keys(closeReports).map((id) => ({
        reportedPlant: closeReports[id][0].reportedPlant,
        reports: closeReports[id],
      }));

      return {
        plants,
        geopos,
      };
    }
  );

  // Reporter leaderboard
  static getReportsToplist = createSelector(ReportSelectors.getReports, (reports) => {
    const sortedReports = [...reports].sort(reportSorter);
    return sortedReports.slice(0, 5);
  });

  static reportListCacheFlag = createSelector(reportStateSelector, (state) => {
    return state.reportListCacheFlag;
  });

  // Fetch data for the form that submits a report
  //  (detail page of a plant for reporting)
  static addReportPlantData = createSelector(
    PlantsSelectors.plantDetail,
    AppSelectors.improvedGeopos,
    AppSelectors.userRegion,
    (plant, geopos, location) => ({ plant, geopos, location })
  );

  // Plants of the catalogue that have `reporting` flag enabled
  static _reportablePlants = createSelector(
    PlantsSelectors.extendedPlantsLimitedByRegion,
    (plants) =>
      plants
        .filter((plant) => !!plant.reporting)
        .map((plant) => ({
          plant: plant,
          bloomingSort: bloomingRelative(plant),
          isTop: false,
        }))
  );

  // Most reported plants
  static _topPlants = createSelector(reportStateSelector, (state) =>
    state.topplantsData.map((plant) => ({ ...plant, isTop: true }))
  );

  // Most reported plants with the plant item extended to full catalogue data (lookup)
  static _topPlantsWithCatalogue = createSelector(
    ReportSelectors._topPlants,
    PlantsSelectors.extendedPlants,
    (topPlants, cataloguePlants) =>
      topPlants.map((item) => ({
        ...item,
        plant: { ...(cataloguePlants.find((pl) => pl.id === item.plant.id) || item.plant) },
      }))
  );

  // Final list of plants to report: reportable plants + top plants comnbined for reporting dashboard
  static getReportingPlantsList = createSelector(
    reportStateSelector,
    PlantsSelectors.plantListNetworkState,
    ReportSelectors._reportablePlants,
    ReportSelectors._topPlantsWithCatalogue,
    (state, plantsLoading, plantList, topPlants) => ({
      loading: state.topplantsDataLoading?.loading || plantsLoading?.loading,
      items: combinePlantlists(plantList, topPlants),
      loadingState: combineNetworkState(plantsLoading, state.topplantsDataLoading),
    })
  );

  static getActiveSpot = createSelector(reportStateSelector, (state) => state.activeSpot);

  static getActiveSpotReports = createSelector(
    ReportSelectors.getActiveSpot,
    ReportSelectors.getReports,
    (activeSpot, reports) => {
      if (!activeSpot) {
        return null;
      }
      return reports.filter(
        (report: PlantReport) =>
          report.cl_loc === activeSpot.locCluster &&
          report.cl_date === activeSpot.dateCluster &&
          report.reportedPlant.id === activeSpot.plantId
      );
    }
  );

  // Combine selected spot and reports of spot
  static getActiveSpotDetail = createSelector(
    ReportSelectors.getActiveSpot,
    ReportSelectors.getActiveSpotReports,
    (spot, reports) => ({
      spot,
      reports,
    })
  );
}

export interface ReportingList {
  plant: { id; thumbnail; name; species; type };
  isTop: boolean;
  count?;
  bloomingSort?;
}

function reportSorter(a, b) {
  return a.reportDate < b.reportDate ? 1 : -1;
}

function reportPlantsSorter(a, b) {
  return a.blmStartMthRelative - b.blmStartMthRelative;
}

/**
 * Calculate a sorting value for blooming.
 * Currently, it uses simply the relative blooming start month.
 * This means we sort by starting month only (kind of disregards the lenght of the
 * blooming period)
 */
function bloomingRelative(plant) {
  return plant.blmStartMthRelative === undefined ? 99 : plant.blmStartMthRelative;
}

/**
 * combines the two lists, removes duplicates from the second list.
 * @param fromTopPlant
 * @param fromReportig
 * @returns combined list of reportable plants
 */
function combinePlantlists(fromReportig, fromTopPlant): Array<ReportingList> {
  const topPlants = (fromTopPlant || [])
    .map((item) => ({ ...item, isTop: true }))
    .slice(0, TOPPLANTS_COUNT);

  const idsOfTopPlants = topPlants.map((entry) => entry.plant.id);

  const reportableWithoutTopPlants = (fromReportig || [])
    .filter((entry) => idsOfTopPlants.indexOf(entry.plant.id) === -1)
    .map((item) => ({ ...item, isTop: false }))
    .sort(reportPlantsSorter);

  return topPlants.concat(reportableWithoutTopPlants);
}
